import Toast, { ToastProps } from "components/Toast";
import { createContext, ReactNode, useContext, useState, FC } from "react";
import { v4 as uuidv4 } from "uuid";

interface ToastContextType {
  showToast: (
    msg: Omit<ToastProps, "id" | "isVisible" | "onClose" | "index">
  ) => void;
}

// Create context with a default value
const ToastContext = createContext<ToastContextType | undefined>(undefined);

export const useToast = (): ToastContextType => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error("useToast must be used within a ToastProvider");
  }
  return context;
};

type ToastState = Omit<ToastProps, "onClose"> & { id: string };

export const ToastProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [toasts, setToasts] = useState<ToastState[]>([]);

  const showToast = (
    msg: Omit<ToastProps, "id" | "isVisible" | "onClose" | "index">
  ) => {
    const id = uuidv4();
    const newToast: ToastState = {
      id,
      title: msg.title,
      description: msg.description ?? "",
      variant: msg.variant,
      isVisible: true,
      index: 0,
      autoHide: msg.autoHide ?? true,
    };

    setToasts((prevToasts) => [...prevToasts, newToast]);

    if (msg.autoHide ?? true) {
      setTimeout(() => {
        setToasts((prevToasts) =>
          prevToasts.map((toast) =>
            toast.id === id ? { ...toast, isVisible: false } : toast
          )
        );
        setTimeout(() => {
          setToasts((prevToasts) =>
            prevToasts.filter((toast) => toast.id !== id)
          );
        }, 300); // Match the duration of the fadeOut animation
      }, 5000);
    }
  };

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      {toasts.map((toast, index) => (
        <Toast
          key={toast.id}
          id={toast.id}
          index={index}
          title={toast.title}
          description={toast.description}
          isVisible={toast.isVisible}
          variant={toast.variant}
          autoHide={toast.autoHide}
          onClose={() => {
            setToasts((prevToasts) =>
              prevToasts.map((t) =>
                t.id === toast.id ? { ...t, isVisible: false } : t
              )
            );
            setTimeout(() => {
              setToasts((prevToasts) =>
                prevToasts.filter((t) => t.id !== toast.id)
              );
            }, 300); // Match the duration of the fadeOut animation
          }}
        />
      ))}
    </ToastContext.Provider>
  );
};
