type ScoreProps = {
  value: number; // The score value to display
  maxValue?: number; // The maximum possible value (default 10)
  unit?: "normal" | "percentage"; // Only 'normal' or '%' allowed, default is 'normal'
  className?: string;
};

const Score = ({
  value,
  maxValue = 10,
  unit = "normal",
  className,
}: ScoreProps) => {
  const getColor = (score: number) => {
    const normalizedScore = (score / maxValue) * 10;
    if (normalizedScore >= 9) {
      return "#22C55E"; // First green for scores 9-10
    } else if (normalizedScore >= 7) {
      return "#84CC16"; // Second green for scores 7-8
    } else if (normalizedScore >= 4) {
      return "#EAB308"; // Yellow for scores 4-6
    } else {
      return "#DC2626"; // Red for scores 1-3
    }
  };

  const formatValue = (num: number) => {
    if (unit === "percentage") {
      const percentage = num * 10; // Convert to percentage
      return percentage % 1 === 0
        ? percentage.toFixed(0)
        : percentage.toFixed(1);
    }

    // For normal values, show without decimals if it's an integer
    if (num % 1 === 0) {
      return num.toFixed(0);
    }

    // Round down if it's between .1 and .4
    const roundedValue = Math.round(num * 2) / 2;
    return roundedValue % 1 === 0
      ? roundedValue.toFixed(0)
      : roundedValue.toFixed(1);
  };

  return (
    <div className={`${className} space-x-[2px]`}>
      <span
        className={`text-[32px] font-bold`}
        style={{ color: getColor(value) }}
      >
        {formatValue(value)}
      </span>
      {unit === "percentage" ? (
        <span className="text-[#6B7280]">%</span>
      ) : (
        <>
          <span className="text-[#6B7280]">/</span>
          <span className="text-[#6B7280]">{maxValue}</span>
        </>
      )}
    </div>
  );
};

export default Score;
