import { ReactComponent as RoadmapIcon } from "../assets/svg/roadmap.svg";
import { ReactComponent as ModuleIcon } from "../assets/svg/module.svg";

import routes from "./routes";

export const SIDEBAR_ITEMS = [
  {
    title: "Roadmap",
    icon: RoadmapIcon,
    path: routes.roadmap.BASE,
  },
  {
    title: "Module",
    icon: ModuleIcon,
    path: routes.module.BASE,
  },
];
