import React, { useState, useEffect } from "react";
import { Button } from "@material-tailwind/react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { ReactComponent as MicrophoneIcon } from "../../assets/svg/microphone.svg";
import { ReactComponent as StopRecordingIcon } from "../../assets/svg/stopRecording.svg";
import { PauseIcon, PlayIcon } from "@heroicons/react/24/solid";

interface RecordButtonProps {
  onTranscript: (transcript: string) => void;
}

const RecordButton: React.FC<RecordButtonProps> = ({ onTranscript }) => {
  const { transcript, resetTranscript, listening } = useSpeechRecognition();
  const [timer, setTimer] = useState<number>(0);
  const [hasTimer, setHasTimer] = useState<boolean>(false);
  const [isRunning, setIsRunning] = useState<boolean>(false);
  const [isPaused, setIsPaused] = useState<boolean>(false);
  const [isStopped, setIsStopped] = useState<boolean>(false);

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;
    if (isRunning && !isPaused) {
      setHasTimer(true);
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer + 1);
      }, 1000);
    } else if (!isRunning || isPaused) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isRunning, isPaused]);

  if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
    return <p>Browser doesn't support speech recognition.</p>;
  }

  const handleStartRecording = (): void => {
    setIsStopped(true);
    resetTranscript();
    setTimer(0);
    setIsPaused(false);
    setIsRunning(true);
    SpeechRecognition.startListening({ continuous: true, language: "en-US" });
  };

  const handlePauseRecording = (): void => {
    setIsPaused(true);
    SpeechRecognition.stopListening();
  };

  const handleResumeRecording = (): void => {
    setIsPaused(false);
    SpeechRecognition.startListening({ continuous: true, language: "en-US" });
  };

  const handleStopRecording = (): void => {
    setIsStopped(false);
    SpeechRecognition.stopListening();
    setIsRunning(false);
    setIsPaused(false);
    onTranscript(transcript);
  };

  const formatTime = (time: number): string => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  return (
    <div className="relative">
      <Button
        // ripple={false}
        variant="text"
        className="bg-[#DC2626] hover:bg-[#DC2626] focus:bg-opacity-100 active:bg-opacity-100 focus:opacity-100 active:opacity-100 active:bg-[#DC2626] focus:bg-[#DC2626] active:ring-0 hover:shadow-none shadow-none rounded-2xl py-4 px-6 normal-case flex items-center text-xl font-semibold relative z-20 transform active:scale-95 transition-transform duration-200"
        size="lg"
        onClick={
          listening || isStopped ? handleStopRecording : handleStartRecording
        }
      >
        {listening || isStopped ? (
          <div className="flex items-center justify-center gap-2">
            <StopRecordingIcon className="w-6 h-6" />
            <span className="text-white">Finish</span>
          </div>
        ) : (
          <div
            className={`flex items-center justify-center gap-2 transition-transform duration-300 ${
              listening ? "scale-105" : "scale-100"
            }`}
          >
            <MicrophoneIcon className="w-7 h-7" />
            <span className="text-white">Start{timer > 0 && " again"}</span>
          </div>
        )}
      </Button>
      {hasTimer && (
        <div className="select-none w-16 h-9 absolute bg-white text-[#DC2626] -left-[78px] top-[14px] text-sm flex justify-center items-center rounded-full font-bold z-10 slideRightToLeft">
          {formatTime(timer)}
        </div>
      )}
      <div
        className={`absolute top-[14px] text-sm rounded-full font-semibold z-10 ${
          isRunning
            ? "slideLeftToRight -right-[50px]"
            : "slideRightToLeft right-[10px]"
        }`}
      >
        <Button
          size="sm"
          variant="text"
          className="bg-white rounded-full hover:bg-white active:bg-white focus:bg-white flex items-center justify-center w-9 h-9 p-0"
          onClick={isPaused ? handleResumeRecording : handlePauseRecording} // Toggle between pause and resume
        >
          {isPaused ? (
            <PlayIcon className="w-6 h-6" fill="#DC2626" />
          ) : (
            <PauseIcon className="w-6 h-6" fill="#DC2626" />
          )}
        </Button>
      </div>
    </div>
  );
};

export default RecordButton;
