const roadmapBase = "/roadmap";
const moduleBase = "/module";
const helpBase = "/help";

const routes = {
  dashboard: "/",
  roadmap: {
    BASE: roadmapBase,
  },
  module: {
    BASE: moduleBase,
    topic: `${moduleBase}/:topicId`,
  },
  help: {
    BASE: helpBase,
  },
  account: "/account",
  setting: "/setting",
  logOut: "/log-out",
};

export default routes;
