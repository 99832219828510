import { Input } from "@material-tailwind/react";
import Button from "components/UI/Button";
import { useForm } from "react-hook-form";

const Auth = () => {
  const { register, handleSubmit } = useForm({
    defaultValues: {
      username: "",
    },
  });

  const onSubmit = (data: any) => {
    console.log("Form Data:", data);
  };

  return (
    <div className="relative overflow-hidden bg-[#172554]">
      <div className="flex h-screen justify-around items-center opacity-0 blur-md transition-all duration-700 ease-out animate-fadeIn z-30 relative">
        <div>
          <h1 className="text-white text-3xl">
            <span>Welcome</span>
            <br />
            <span>Let’s Get Started.</span>
          </h1>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <Input
                {...register("username")}
                crossOrigin
                type="email"
                placeholder="Enter you email"
                size="lg"
                className="font-semibold min-w-[430px] min-h-14 rounded-[16px] !border !border-gray-300 text-gray-900 placeholder:text-gray-500 placeholder:opacity-100 focus:!border-crystal-blue-700"
              />
              <Button type="submit" showArrowRight>
                Next
              </Button>
            </div>
          </form>
        </div>
        <div className="relative">
          <div className="w-[810px] h-[938px] rounded-[60px] bg-white/20 flex items-center justify-center  animated-element"></div>
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <h1 className="text-white text-5xl">SkillWay</h1>
          </div>
        </div>
      </div>

      <div className="absolute -bottom-36 left-12 w-[860px] h-[860px] bg-[#4C0519] rounded-full blur-[110px] z-10"></div>
      <div className="top-9 right-16 absolute w-[500px] h-[500px] bg-[#1E3A8A] rounded-full blur-[110px] z-10"></div>
    </div>
  );
};
export default Auth;
