import Layout from "components/Layout";
import React from "react";

const Roadmap: React.FC = () => {
  // Dynamic content for the secondary sidebar
  const secondarySidebar = (
    <div className="z-20 min-[w-360px] w-[360px] bg-[#ffffff0a]"></div>
  );

  return <div className="text-white text-4xl">Hello world</div>;
};

export default Roadmap;
