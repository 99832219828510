import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Button,
} from "@material-tailwind/react";
import React, { ReactNode, useState } from "react";
import { SIDEBAR_ITEMS } from "constants/sidebar";
import { Link, Outlet, useLocation } from "react-router-dom";
import {
  ChevronDoubleRightIcon,
  ChevronDownIcon,
} from "@heroicons/react/24/solid";
import { ReactComponent as HideSidebarIcon } from "assets/svg/hideSIdebar.svg";

interface LayoutProps {
  secondarySidebar?: ReactNode;
}

function Icon({ id, open }: any) {
  return (
    <ChevronDownIcon
      className={`${
        id === open ? "rotate-180" : ""
      } h-5 w-5 transition-transform mr-2`}
    />
  );
}

const Layout: React.FC<LayoutProps> = ({ secondarySidebar }) => {
  const location = useLocation();

  const isActive = (path: string) => {
    return location.pathname === path;
  };

  const [open, setOpen] = React.useState(0);

  const handleOpen = (value: any) => setOpen(open === value ? 0 : value);

  const [percentage, setPercentage] = useState(80);

  const [isSidebarHidden, setIsSidebarHidden] = useState(false);
  const toggleSidebar = () => setIsSidebarHidden((prev) => !prev);

  return (
    <div className="flex h-screen relative overflow-hidden bg-[#F3F4F6]">
      {/* Left Sidebar */}
      <aside className="z-20 bg-[#111827] w-[120px] text-white flex-shrink-0 p-4 flex flex-col justify-between items-center py-5 px-3 relative">
        {isSidebarHidden && (
          <div
            className="w-10 h-10 flex items-center justify-center rounded-xl absolute top-4 right-[-50px] bg-black shadow-md cursor-pointer transition-all hover:bg-gray-800"
            onClick={toggleSidebar}
          >
            <ChevronDoubleRightIcon width={30} className="m-2" />
          </div>
        )}

        <div className="mb-8">
          <h1 className="text-2xl font-bold text-center">Logo</h1>
          <ul className="pt-20">
            {SIDEBAR_ITEMS.map((item) => (
              <li key={item.title} className="mb-4">
                <Link to={item.path}>
                  <Button
                    fullWidth
                    variant="text"
                    className={`flex items-center flex-col rounded-xl gap-1 hover:bg-white/10 max-w-24 py-2 ${
                      isActive(item.path)
                        ? "bg-gradient-to-br from-white/30 to-white/10"
                        : ""
                    }`}
                  >
                    <item.icon className="w-6 text-white" />
                    <span className="text-xs font-light text-white">
                      {item.title}
                    </span>
                  </Button>
                </Link>
              </li>
            ))}
          </ul>
        </div>

        <div>Profile</div>
      </aside>

      <div
        className={`min-[w-360px] w-[360px] bg-[#1F2937] p-5 transition-transform duration-300 z-10 ${
          isSidebarHidden ? "transform -translate-x-full" : ""
        }`}
      >
        <div className="flex items-center justify-between text-white mb-4">
          <span className="text-xl font-medium">Topics</span>
          <HideSidebarIcon
            onClick={toggleSidebar}
            className="cursor-pointer transition-all hover:opacity-70"
          />
        </div>
        <Accordion
          open={open === 1}
          icon={<Icon id={1} open={open} />}
          className="rounded-xl bg-gradient-to-br from-white/40 to-white/10 border border-gray-800 px-2 hover:none focus:none active:none"
        >
          <AccordionHeader
            onClick={() => handleOpen(1)}
            className="text-white border-none hover:none focus:none active:none"
          >
            <div className="flex items-center gap-3">
              <div
                className={`w-14 h-14 rounded-full border flex items-center font-extrabold text-base justify-center ${
                  percentage > 40 ? "text-black" : "text-white"
                }`}
                style={{
                  background: `linear-gradient(0deg, #F7E758 ${percentage}%, transparent ${percentage}%)`,
                }}
              >
                <span>
                  {percentage}
                  <span className="text-xs">%</span>
                </span>
              </div>
              <div className="font-medium text-base">Behavioural</div>
            </div>
          </AccordionHeader>
          <AccordionBody>
            <div className="text-white -mt-4">
              <ul className="pl-1 relative item-list-sidebar">
                <li className="flex items-center gap-3 text-sm font-normal hover:bg-black/25 px-4 py-2 rounded-xl cursor-pointer transition-all">
                  <div className="w-4 h-4 border rounded-full z-10 bg-[#68718e]"></div>
                  Cultural Fit
                </li>
                <li className="flex items-center gap-3 text-sm font-bold hover:bg-black/25 px-4 py-2 rounded-xl cursor-pointer transition-all">
                  <div className="w-4 h-4 bg-white rounded-full"></div>
                  Problem-Solving
                </li>
                <li className="flex items-center gap-3 text-sm font-normal hover:bg-black/25 px-4 py-2 rounded-xl cursor-pointer transition-all">
                  <div className="w-4 h-4 border rounded-full z-10 bg-[#68718e]"></div>
                  Leadership and Initiative
                </li>
                <li className="flex items-center gap-3 text-sm font-normal hover:bg-black/25 px-4 py-2 rounded-xl cursor-pointer transition-all">
                  <div className="w-4 h-4 border rounded-full z-10 bg-[#68718e]"></div>
                  Conflict Resolution
                </li>
              </ul>
            </div>
          </AccordionBody>
        </Accordion>
      </div>

      {/* Secondary Sidebar */}
      {/* {secondarySidebar && secondarySidebar} */}

      {/* Main Content and Secondary Sidebar Wrapper */}
      <div className="flex-grow flex">
        {/* Main Content */}
        <main className="z-30 p-9 flex justify-center flex-grow">
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default Layout;
