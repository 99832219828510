import { RouteObject } from "react-router-dom";
import { Auth, Evaluation, Help, Interview, Module, Roadmap } from "./pages";
import Resume from "pages/Resume";
import Layout from "./components/Layout";
import Admin from "pages/Admin";
import routes from "constants/routes";

const routesConfig: RouteObject[] = [
  {
    path: "/",
    element: <Layout />,
    children: [
      { path: "roadmap", element: <Roadmap /> },
      { path: "module", element: <Module /> },
      { path: routes.module.topic, element: <Evaluation /> },
      { path: "resume", element: <Resume /> },
      { path: "interview", element: <Interview /> },
      { path: "help", element: <Help /> },
      { path: "admin", element: <Admin /> },
    ],
  },
  { path: "/auth", element: <Auth /> },
];

export default routesConfig;
