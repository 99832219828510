import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";

export type ToastProps = {
  id: string;
  title: string;
  description?: string;
  isVisible: boolean;
  variant?: "success" | "error" | "warning";
  onClose: () => void;
  index: number;
  autoHide?: boolean;
};

const Toast = ({
  title,
  description,
  isVisible,
  variant = "warning",
  onClose,
  index,
  autoHide,
}: ToastProps) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (isVisible) {
      setShow(true);
    } else {
      setTimeout(() => setShow(false), 300); // Match the duration of the fadeOut animation
    }
  }, [isVisible]);

  useEffect(() => {
    if (autoHide && isVisible) {
      const timer = setTimeout(() => {
        setShow(false);
        onClose();
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [autoHide, isVisible, onClose]);

  if (!show) {
    return null;
  }

  const getIcon = () => {
    switch (variant) {
      case "success":
        return <CheckCircleIcon className="w-6 text-[#34d399]" />;
      case "error":
        return <ExclamationCircleIcon className="w-6 text-[#ef4444]" />;
      case "warning":
        return <ExclamationTriangleIcon className="w-6 text-[#f59e0b]" />;
      default:
        return null;
    }
  };

  return (
    <div
      className={`fixed right-4 bg-white rounded-lg shadow-lg p-4 border-[1px] border-gray-300 flex flex-col items-start z-50 ${
        isVisible ? "animate-fadeIn" : "animate-fadeOut"
      }`}
      style={{ top: `${index * 105 + 20}px` }} // Adjusted to stack toasts with spacing
    >
      <div className="flex w-full justify-between items-start">
        <div className="flex space-x-3">
          <div>{getIcon()}</div>
          <div className="space-y-1">
            <p className="text-sm font-medium">{title}</p>
            <p className="text-sm max-w-80 text-gray-700">{description}</p>
          </div>
        </div>
        <button
          onClick={onClose}
          className="text-gray-500 hover:text-gray-700 ml-6"
        >
          <XMarkIcon className="w-5 h-5" />
        </button>
      </div>
    </div>
  );
};

export default Toast;
