import { ArrowRightIcon } from "@heroicons/react/24/solid";
import { Button as ButtonComponent } from "@material-tailwind/react";

type ButtonProps = {
  children: React.ReactNode;
  beforeIcon?: React.ReactNode;
  afterIcon?: React.ReactNode;
  showArrowRight?: boolean;
} & React.ComponentProps<typeof ButtonComponent>;

const Button = ({
  children,
  beforeIcon,
  afterIcon,
  showArrowRight = false,
  ...props
}: ButtonProps) => {
  return (
    <ButtonComponent
      className="bg-[#F7E758] text-black rounded-2xl font-medium flex items-center normal-case"
      size="lg"
      {...props}
    >
      {beforeIcon && beforeIcon}
      {children}
      {afterIcon && afterIcon}
      {showArrowRight && <ArrowRightIcon className="w-5 h-5 ml-2" />}{" "}
      {/* Conditionally render the icon */}
    </ButtonComponent>
  );
};

export default Button;
