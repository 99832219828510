import {
  Button,
  Drawer,
  IconButton,
  Tab,
  TabPanel,
  Tabs,
  TabsBody,
  TabsHeader,
  Typography,
} from "@material-tailwind/react";
import React, { useState } from "react";
import { ReactComponent as MicrophoneIcon } from "../../assets/svg/microphone.svg";
import RecordButton from "components/RecordButton";
import { AccordionCustomIcon } from "components/Accordion";
import Score from "components/Score";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { ReactComponent as DocumentIcon } from "assets/svg/document.svg";
import { useNavigate, useParams } from "react-router-dom";
import { CheckCircleIcon } from "@heroicons/react/24/outline";

const Module: React.FC = () => {
  const { topicId } = useParams();
  const navigate = useNavigate();

  console.log(topicId);

  const [showResultOverviewDrawer, setShowResultOverviewDrawer] =
    useState(false);

  // Dynamic content for the secondary sidebar
  const secondarySidebar = (
    <div className="z-20 min-[w-360px] w-[360px] bg-[#ffffff0a]"></div>
  );

  const data = [
    {
      label: "Attention to Detail",
      value: "attentionToDetails",
      score: 2.4,
    },
    {
      label: "Problem-Solving",
      value: "problemSolving",
      score: 7,
    },
    {
      label: "Collaboration",
      value: "collaboration",
      score: 9.4,
    },
    {
      label: "Technical Skills",
      value: "technicalSkills",
      score: 6,
    },
  ];

  return (
    <>
      <div className="max-w-[600px] opacity-0 blur-md transition-all duration-700 ease-out animate-fadeIn">
        <div className="flex items-center flex-col">
          <div className="text-black text-3xl text-center font-bold">
            Cultural Fit
          </div>
          <div className="text-black mt-3 font-light text-2xl text-center">
            Assess how past experiences shapeyour problem-solving and teamwork
            skills.
          </div>
        </div>
        <div className="mt-8 mb-6 text-base text-gray-800 text-center">
          Tap on below items to proceed:
        </div>

        <div className="grid grid-cols-3 gap-4">
          <div
            onClick={() => navigate("/module/problem-solving")}
            className="bg-white p-4 rounded-xl cursor-pointer hover:opacity-70 transition-all shadow-md flex justify-between flex-col space-y-8"
          >
            <div className="flex items-center justify-between">
              <span className="text-xl text-gray-700 font-semibold">1</span>
              <CheckCircleIcon width={30} color="teal" />
            </div>
            <div className="text-black font-semibold text-xl">
              Navigating Organizational Change
            </div>
          </div>
          <div
            onClick={() => setShowResultOverviewDrawer(true)}
            className="bg-white p-4 rounded-xl cursor-pointer hover:opacity-70 transition-all shadow-md flex justify-between flex-col space-y-8"
          >
            <div className="flex items-center justify-between">
              <span className="text-xl text-gray-700 font-semibold">2</span>
              <CheckCircleIcon width={30} color="teal" />
            </div>
            <div className="text-black font-semibold text-xl">
              Receiving and Implementing Feedback
            </div>
          </div>
          <div
            onClick={() => setShowResultOverviewDrawer(true)}
            className="bg-white p-4 rounded-xl cursor-pointer hover:opacity-70 transition-all shadow-md flex justify-between flex-col space-y-8"
          >
            <div className="flex items-center justify-between">
              <span className="text-xl text-gray-700 font-semibold">3</span>
              <CheckCircleIcon width={30} color="teal" />
            </div>
            <div className="text-black font-semibold text-xl">
              Defining Teamwork and Collaboration Strategies
            </div>
          </div>
          <div
            onClick={() => setShowResultOverviewDrawer(true)}
            className="bg-white p-4 rounded-xl cursor-pointer hover:opacity-70 transition-all shadow-md flex justify-between flex-col space-y-8"
          >
            <div className="flex items-center justify-between">
              <span className="text-xl text-gray-700 font-semibold">4</span>
              <CheckCircleIcon width={30} color="teal" />
            </div>
            <div className="text-black font-semibold text-xl">
              Fostering a Positive Team Environment
            </div>
          </div>
          <div
            onClick={() => setShowResultOverviewDrawer(true)}
            className="bg-white p-4 rounded-xl cursor-pointer hover:opacity-70 transition-all shadow-md flex justify-between flex-col space-y-8"
          >
            <div className="flex items-center justify-between">
              <span className="text-xl text-gray-700 font-semibold">5</span>
            </div>
            <div className="text-black font-semibold text-xl">
              Aligning with Company Values
            </div>
          </div>
          <div
            onClick={() => setShowResultOverviewDrawer(true)}
            className="bg-white p-4 rounded-xl cursor-pointer hover:opacity-70 transition-all shadow-md flex justify-between flex-col space-y-8"
          >
            <div className="flex items-center justify-between">
              <span className="text-xl text-gray-700 font-semibold">6</span>
            </div>
            <div className="text-black font-semibold text-xl">
              Encouraging Open Communication
            </div>
          </div>
          <div
            onClick={() => setShowResultOverviewDrawer(true)}
            className="bg-white p-4 rounded-xl cursor-pointer hover:opacity-70 transition-all shadow-md flex justify-between flex-col space-y-8"
          >
            <div className="flex items-center justify-between">
              <span className="text-xl text-gray-700 font-semibold">7</span>
            </div>
            <div className="text-black font-semibold text-xl">
              Aligning with Company Values
            </div>
          </div>
        </div>
      </div>
      <Drawer
        placement="right"
        open={showResultOverviewDrawer}
        onClose={() => setShowResultOverviewDrawer(false)}
        className="p-10"
        size={570}
      >
        <div className="mb-6 flex items-center justify-between">
          <div className="flex items-center gap-2">
            <Typography variant="h5" color="blue-gray">
              Results Overview
            </Typography>
            <Score value={8.5} />
          </div>

          <IconButton
            variant="text"
            color="blue-gray"
            onClick={() => setShowResultOverviewDrawer(false)}
          >
            <XMarkIcon className="w-6" />
          </IconButton>
        </div>
        <div className=" divide-solid divide-y">
          <div className="space-y-2">
            <Typography color="black" className="font-extrabold text-xl">
              Your Response:
            </Typography>
            <audio controls className="w-full">
              <source src={""} type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>

            <p className="text-base text-gray-900 font-normal pt-2">
              In my previous role as a Product Manager at a tech startup, the
              company’s mission was to create accessible educational tools for
              underprivileged communities. This mission resonated deeply with my
              personal values of inclusivity and the belief that education is a
              fundamental right. I was leading a project to develop a mobile app
              that provided free educational resources and tutorials for users
              in underserved areas. My alignment with the company's mission
              motivated me to go above and beyond in my work. I organized focus
              groups within those communities to gather feedback directly from
              potential users, ensuring that the app's features genuinely
              addressed their needs. This commitment to our mission not only
              helped us create a more user-centric product but also fostered a
              sense of purpose within my team. We were driven by the knowledge
              that our work could positively impact lives, which significantly
              enhanced our collaboration and productivity. As a result, the app
              was well-received upon launch, and we saw increased engagement
              from users, validating our efforts and reinforcing my dedication
              to aligning personal and organizational values.
            </p>
          </div>

          <div className="my-10 pt-5">
            <div className="flex justify-between items-center mb-3">
              <div className="text-xl font-extrabold">Scores</div>
              <Score value={6} />
            </div>
            <Tabs value="attentionToDetails">
              <TabsHeader
                className="bg-transparent border border-[#d6d7df] rounded-2xl"
                indicatorProps={{
                  className:
                    "bg-[#e2e3f0] shadow-none !text-gray-900 border border-[#1951CC] rounded-xl",
                }}
              >
                {data.map(({ label, value, score }) => (
                  <Tab key={value} value={value}>
                    <span className="text-[10px] font-bold text-[#111827]">
                      {label}
                    </span>
                    <Score value={score} className="mt-[-10px]" />
                  </Tab>
                ))}
              </TabsHeader>
              <TabsBody>
                {data.map(({ value, score }) => (
                  <TabPanel key={value} value={value}>
                    {score}
                  </TabPanel>
                ))}
              </TabsBody>
            </Tabs>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default Module;
