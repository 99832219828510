import { useRoutes } from "react-router-dom";
import routesConfig from "./routesConfig";
import { ToastProvider } from "hooks/useToast";

function App() {
  const routing = useRoutes(routesConfig);

  return <ToastProvider>{routing}</ToastProvider>;
}

export default App;
